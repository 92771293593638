import React from 'react';
import styles from './VideoPlayer.module.scss';
import ReactPlayer from 'react-player';

const VideoPlayer = props => {
  const { url } = props;
  return (
    <div className={styles.videoPlayer}>
      <div className={styles.container}>
        <ReactPlayer url={url} playing autoPlay muted height="" />
        <p>Image blurred intentionally</p>
      </div>
    </div>
  );
};

export default VideoPlayer;
