import React from 'react';
import styles from './Row.module.scss';

const Row = props => {
  const { filled, current, capacity, rating } = props;
  if (filled !== null) {
    return (
      <div className={styles.container}>
        <h3>Occupancy (Est)</h3>
        <div className={styles.counter}>
          <h5>
            {current}/{capacity}
          </h5>
          {rating === 'green' && <h5 className={styles.counterGreen}>({filled})</h5>}
          {rating === 'yellow' && <h5 className={styles.counterYellow}>({filled})</h5>}
          {rating === 'red' && <h5 className={styles.counterRed}>({filled})</h5>}
        </div>
      </div>
    );
  }
  return (
    <div className={styles.container}>
      <h3 className={styles.offlineMessage}>You are offline, please refresh</h3>
    </div>
  );
};

export default Row;
