import React, { Component } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import Starbucks from '../Pages/Starbucks/Starbucks';
import Ullman from '../Pages/Ullman/Ullman';

class Routes extends Component {
  render() {
    return (
      <BrowserRouter>
        <Switch>
          <Route exact path={'/'} component={Starbucks} />
          <Route exact path={`/starbucks`} component={Starbucks} />
          <Route exact path={`/ullman`} component={Ullman} />
        </Switch>
      </BrowserRouter>
    );
  }
}

export default Routes;
