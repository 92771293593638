import React, { Component } from 'react';
import Navigation from '../../Containers/Navigation/Navigation';
import Row from '../../components/Counter/Row';
import VideoPlayer from '../../components/VideoPlayer/VideoPlayer';
import { fetchSpace } from '../../actions/index';
import Chart from '../../Containers/Chart/Chart';
import styles from './Ullman.module.scss';

let timer;
class Ullman extends Component {
  state = {
    data: null
  };

  componentDidMount() {
    this.fetchSpace();
    timer = setInterval(() => this.fetchSpace(), 5000);
  }

  componentWillUnmount() {
    clearInterval(timer);
  }

  fetchSpace = async () => {
    const response = await fetchSpace(process.env.REACT_APP_ULLMAN_SPACE_ID);
    if (response) {
      this.setState({
        data: response
      });
    }
  };
  render() {
    if (!this.state.data) {
      return <div />;
    }
    const { current_count, capacity } = this.state.data;
    const filled = (current_count / capacity) * 100;
    const rating = filled <= 50 ? 'green' : filled > 75 ? 'red' : 'yellow';
    return (
      <>
        <Navigation title="Ullman Commons" />
        <Row filled={`${Math.round(filled)}%`} current={current_count} capacity={capacity} rating={rating} />
        {process.env.REACT_APP_ULLMAN_VIDEO_URL && <VideoPlayer url={process.env.REACT_APP_ULLMAN_VIDEO_URL} />}
        {!process.env.REACT_APP_ULLMAN_VIDEO_URL && (
          <img src="./images/ullman.jpeg" alt="Ullman" className={styles.image} />
        )}
        <Chart spaceId={process.env.REACT_APP_ULLMAN_SPACE_ID} />
      </>
    );
  }
}

export default Ullman;
