import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import styles from './Navigation.module.scss';

class Navigation extends PureComponent {
  static propTypes = {
    title: PropTypes.string.isRequired,
    hours: PropTypes.string.isRequired
  };

  state = {
    openMenu: false
  };

  menuHandler = () => {
    this.setState({
      openMenu: !this.state.openMenu
    });
  };

  render() {
    const { title, hours } = this.props;
    return (
      <>
        {!this.state.openMenu && (
          <div className={styles.container} onClick={this.menuHandler}>
            <img src="./images/menu.svg" alt="Burger Menu" className={styles.burgerMenu} />
            <h3 className={styles.title}>{title}</h3>
            {hours && (
              <div>
                <p className={styles.hoursTitle}>Reg Hours</p>
                <p className={styles.hours}>{hours}</p>
              </div>
            )}
          </div>
        )}
        {this.state.openMenu && (
          <div className={styles.containerOpen} onClick={this.menuHandler}>
            <img src="./images/menu.svg" alt="Burger Menu" className={styles.burgerMenu} />
            <Link to="starbucks" className={styles.link}>
              <h3 className={styles.title}>Starbucks</h3>
            </Link>
            <Link to="/ullman" className={styles.link}>
              <h3 className={styles.title}>Ullman Commons</h3>
            </Link>
          </div>
        )}
      </>
    );
  }
}

export default Navigation;
