export const host = 'https://api.density.io/v2';

const endpoints = {
  spaces: '/spaces/',
  spaceCounts: '/spaces/REPLACE_SPACE_ID/counts/'
};

const config = {
  method: 'GET',
  headers: {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${process.env.REACT_APP_BEARER_TOKEN}`
  }
};

export const fetchSpace = async id => {
  try {
    const response = await fetch(`${host}${endpoints.spaces}${id}`, config);
    if (response.ok) return response.json();
    return null;
  } catch (error) {
    return error;
  }
};

export const fetchSpaceCounts = async (id, startTime, endTime) => {
  try {
    const response = await fetch(
      `${host}${endpoints.spaceCounts.replace(
        'REPLACE_SPACE_ID',
        id
      )}?start_time=${startTime}&end_time=${endTime}&interval=1h&page_size=1500`,
      config
    );
    if (response.ok) return response.json();
    return null;
  } catch (error) {
    return error;
  }
};
