import React, { PureComponent } from 'react';
import { ResponsiveContainer, BarChart, Bar, XAxis, YAxis, Legend, Tooltip } from 'recharts';
import { fetchSpaceCounts } from '../../actions/index';
import styles from './Chart.module.scss';
import moment from 'moment-timezone';

class Chart extends PureComponent {
  state = {
    data: null
  };

  componentDidMount() {
    this.fetchSpaceCounts();
  }

  fetchSpaceCounts = async () => {
    const startTime = `${moment()
      .tz('America/Los_Angeles')
      .subtract(4, 'weeks')
      .format('YYYY-MM-DD')}T07:00:00.000Z`;
    const endTime = `${moment()
      .tz('America/Los_Angeles')
      .subtract(1, 'day')
      .format('YYYY-MM-DD')}T07:00:00.000Z`;
    const response = await fetchSpaceCounts(this.props.spaceId, startTime, endTime);
    if (response && response.results) {
      let count = 0;
      const modifiedResponse = response.results.reduce((obj, item) => {
        if (
          moment(item.timestamp)
            .tz('America/Los_Angeles')
            .format('dddd') ===
          moment()
            .tz('America/Los_Angeles')
            .format('dddd')
        ) {
          count = item.interval.analytics.max;
          obj[
            moment(item.timestamp)
              .tz('America/Los_Angeles')
              .format('HH')
          ] = obj[
            moment(item.timestamp)
              .tz('America/Los_Angeles')
              .format('HH')
          ]
            ? obj[
                moment(item.timestamp)
                  .tz('America/Los_Angeles')
                  .format('HH')
              ] + count
            : count;
        }
        return obj;
      }, {});

      const values = [];
      for (let i = 7; i < 24; i++) {
        const label = i <= 12 ? i : i - 12;
        values.push({
          name: i < 12 ? `${label}a` : `${label}p`,
          [`${moment()
            .tz('America/Los_Angeles')
            .format('dddd')} historical average peak occupancy by hour.`]: modifiedResponse[i]
            ? Math.round(modifiedResponse[i] / 4)
            : Math.round(modifiedResponse[`0${i}`] / 4)
        });
      }
      this.setState({
        data: values
      });
    }
  };
  render() {
    if (!this.state.data) {
      return <div />;
    }
    const { data } = this.state;
    return (
      <div className={styles.container}>
        <ResponsiveContainer width="100%" height={500}>
          <BarChart
            data={data}
            margin={{
              right: 10,
              bottom: 10
            }}
          >
            <YAxis />
            <XAxis dataKey="name" />
            <Legend />
            <Tooltip />
            <Bar
              dataKey={`${moment()
                .tz('America/Los_Angeles')
                .format('dddd')} historical average peak occupancy by hour.`}
              fill="#413ea0"
            />
          </BarChart>
        </ResponsiveContainer>
      </div>
    );
  }
}

export default Chart;
